<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Links</template>

        <button v-if="canPost" class="btn btn-block btn-medium" @click="createItem">
          Nuevo Link
        </button>
        <vue-table
          v-if="vTable.headers"
          :values="items"
          :headers="vTable.headers"
          :actions="actions"
          :options="vTable.options"
          ref="vtable"
          :empty_message="canGet ? `No hay links para mostrar` : `No tienes permisos para ver links`"
          @changed="getItems"
          @onShowItem="onShowItem"
          @onDeleteItem="deleteItem"
        />
      </Widget>
    </div>

    <LinkModal v-if="showModal" @close="closeModal" :item="selItem" @saved="onSaveItem"/>
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import LinkModal from "./LinkModal.vue";
import {can} from '@/acl/methods'

export default {
  components: {
    Widget,
    LinkModal,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: "title",
          mask: "Titulo",
          sortable: true,
        },
        {
          title: "url",
          mask: "Url",
        },
      ],
      actions: [
        {
          buttonClass: "btn-warning",
          callback: "onShowItem",
          tooltip: "Editar Link",
          icon: "fa-solid fa-pencil",
        },
        {
          buttonClass: "btn-danger",
          callback: "onDeleteItem",
          tooltip: "Borrar Link",
          icon: "fa-solid fa-trash",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    items() {
      return this.$store.getters.links;
    },
    canGet(){
      return can(this.$store.getters.user, 'links', ['get'])
    },
    canPost(){
      return can(this.$store.getters.user, 'links', ['post'])
    },
    canPut(){
      return can(this.$store.getters.user, 'links', ['put'])
    },
    canDel(){
      return can(this.$store.getters.user, 'links', ['delete'])
    },
    actions(){
      const actions = this.vTable.actions
      if (!this.canPut) {
        actions.splice(actions.findIndex(a => a.callback === 'onShowItem'), 1)
      }
      if (!this.canDel) {
        actions.splice(actions.findIndex(a => a.callback === 'onDeleteItem'), 1)
      }
      return actions
    }
  },
  methods: {
    initializeTable() {
      this.$refs.vtable.init();
    },
    getItems(params) {
      this.$store.dispatch("getLinks", params);
    },
    closeModal() {
      this.showModal = false;
      this.selItem = null;
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = item;
    },
    createItem() {
      this.showModal = true;
    },
     onSaveItem() {
      this.initializeTable(); // Reset table
    },
    deleteItem(item) {
      this.$alert
        .fireConfirm("Borrar Link", "Esta seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("deleteLink", item.link_id).then(() => {
           this.onSaveItem();
        });
        });
    },
  },
};
</script>
