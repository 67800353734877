<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Contacto</template>
    <template #body>
      <FormText
        label="Titulo"
        icon="fa-solid fa-align-left"
        v-model="form.title"
      />
      <FormText label="URL" icon="fa-solid fa-link" v-model="form.url" />
      <FormUploader imageType @onChange="onImgUploaded">
        <button class="btn btn-primary">Subir Imagen</button>
      </FormUploader>
      <div
        class="img-container img-container-md contain d-inline-block"
        :style="`background-image: url('${form.img.url}')`"
        v-if="form.img?.url"
      >
        <div class="img-buttons">
          <button class="btn btn-outline-danger btn-rounded" @click="deleteImg">
            <i class="fa-solid fa-trash"></i>
          </button>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="btn btn-primary" @click="saveData">Guardar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormUploader from "../../../components/Form/FormUploader.vue";

export default {
  components: {
    FormText,
    FormUploader
},
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      title: null,
      url: null,
    },
  }),
  mounted() {
    if (this.item) {
      this.form = this.item;
      this.form.img = {
        url: this.item.img_url,
      };
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveData() {
      const form = new FormData();
      form.append("title", this.form.title);
      form.append("url", this.form.url);
      if (this.form.img?.file) form.append("img", this.form.img.file);
      if (this.form.delete_img) form.append("delete_img", true);
      
      if (this.form.link_id) {
        this.$store
          .dispatch("updateLink", { id: this.form.link_id, form })
          .then(() => this.close());
      } else {
        this.$store.dispatch("createLink", form).then(() => this.close());
      }
      this.$emit('saved')
    },
    onImgUploaded({ file, url }) {
      this.form.img = {
        file,
        url,
      };
    },
    deleteImg() {
      this.form.img = null;
      this.form.delete_img = true;
    },
  },
};
</script>
<style></style>
